import { Fragment, useId, useRef, useState } from "react"
import { Accordion, Button, useAccordionButton } from "react-bootstrap"
import { BsChevronRight } from "react-icons/bs"
import { CustomTableRow } from "../CustomTableRow"

export function CustomTableAccordionRow<T>(
  props: CustomTableAccordionRowProps<T>,
) {
  const {
    item,
    index,
    accordionBody,
    columns,
    rowId,
    rowClassName,
    rowShouldHaveArrow,
  } = props
  const eventKey = useId()
  const [collapsed, setCollapsed] = useState(true)
  const collapseTr = useRef<HTMLTableRowElement>(null)
  const button = useRef<HTMLButtonElement>(null)
  const handleClick = useAccordionButton(eventKey, () => {
    if (collapsed) {
      button.current?.classList.remove("table-accordion-button-collapsed")
      collapseTr.current?.classList.remove("border-transparent")
    } else {
      button.current?.classList.add("table-accordion-button-collapsed")
      collapseTr.current?.classList.add("border-transparent")
    }
    setCollapsed(!collapsed)
  })

  return (
    <>
      <tr>
        <td className={`w-1 ${rowClassName ?? ""}`}>
          {rowShouldHaveArrow(item) && (
            <Button
              ref={button}
              variant="link"
              className="table-accordion-button table-accordion-button-collapsed p-0 d-flex align-content-center justify-content-center"
              onClick={handleClick}
            >
              <BsChevronRight />
            </Button>
          )}
        </td>
        <CustomTableRow
          item={item}
          index={index}
          columns={columns}
          rowId={rowId}
          rowClassName={rowClassName}
        />
      </tr>
      <tr className="border-transparent table-bg-state-white" ref={collapseTr}>
        <td className="p-0" colSpan={columns.length + 1}>
          <div className="d-flex">
            <Accordion.Collapse className="w-100" eventKey={eventKey}>
              <div className="px-5 py-4 bg-secondary bg-opacity-10">
                {accordionBody(item, collapsed, index)}
              </div>
            </Accordion.Collapse>
          </div>
        </td>
      </tr>
    </>
  )
}

import { Form, FormLabelProps } from "react-bootstrap"

export function Label(
  props: FormLabelProps & { required?: boolean; complement?: React.ReactNode },
) {
  const { required, children, complement, className, ...otherProps } = props

  const labelClassName = className ?? (complement ? "d-flex gap-1" : undefined)

  return (
    <Form.Label className={labelClassName} {...otherProps}>
      {children}
      {required && <span className="text-danger"> *</span>}
      {complement}
    </Form.Label>
  )
}

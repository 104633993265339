import { Fragment, useContext, useState } from "react"
import {
  Button,
  Collapse,
  OverlayTrigger,
  Table,
  Tooltip,
} from "react-bootstrap"
import {
  BsCheckCircleFill,
  BsChevronDown,
  BsChevronRight,
  BsClockFill,
  BsFileTextFill,
  BsInfoCircleFill,
  BsXCircleFill,
} from "react-icons/bs"
import { FormatMoney } from "../../../../../../components/Formats/FormatMoney"
import { intlFormatDateTime } from "../../../../../../utils/timezoneHelpers"
import { dashOrName } from "../../../../../../utils/dashOrName"
import { convertSolutions } from "../../../../../../utils/gooxxySolutionsEnum"
import CheckinTableActionButtons from "../ActionButtons"
import CheckinTableHead from "../CheckinTableHead"
import { styled } from "styled-components"
import { ChangeStatusPopover } from "../../../../../../components/ChangeStatusPopover"
import { t } from "i18next"

import ToastContext from "../../../../../../context/ToastContext"
import {
  revertStatusCheckin,
  updateStatusCheckin,
} from "../../../../../../services/Stock.service"

interface CheckinTableRowProps {
  checkins: StockCheckin[]
  isLoading: boolean
  handleReloadTable?: (_value: boolean) => void
  handleFeedback: (
    show: boolean,
    message: string | JSX.Element,
    type: string,
  ) => void
  handleDeleteCheckin: (id: number) => void
  handleSolutions(solutions: gooxxySolutions[]): Record<string, string[]>
  success: boolean
}

const StyledTd = styled.td`
  padding: 0 !important;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export default function CheckinTableRow(props: CheckinTableRowProps) {
  const {
    checkins,
    isLoading,
    handleReloadTable,
    handleFeedback,
    handleDeleteCheckin,
    handleSolutions,
    success,
  } = props

  const formatSolutionsText = (solutions: gooxxySolutions[]) => {
    return solutions
      .map((solution) => convertSolutions(solution.code))
      .join(", ")
  }

  const badgeMap = {
    awaiting_identification: {
      variant: "secondary",
      icon: <BsFileTextFill />,
      className: "",
    },
    awaiting_analysis: {
      variant: "warning",
      icon: <BsClockFill />,
      className: "",
    },
    available: {
      variant: "success",
      icon: <BsCheckCircleFill />,
      className: "",
    },
    non_adherent: { variant: "danger", icon: <BsXCircleFill />, className: "" },
  }

  const toastContext = useContext(ToastContext)
  const showToastSuccess = toastContext?.success || (() => {})
  const showToastInfo = toastContext?.info || (() => {})
  const showToastDanger = toastContext?.danger || (() => {})

  function generateColumns(
    checkin: StockCheckin,
    subcheckin: boolean,
    originalCheckin?: StockCheckin,
  ) {
    const [isOpen, setIsOpen] = useState(false)
    const [currentStatus, setCurrentStatus] = useState(
      checkin.status || "awaiting_identification",
    )

    const handleStatusChange = (newStatus: string) => {
      setCurrentStatus(newStatus)
    }

    const handleEditStatusCheckin = async (
      id: number,
      newStatus: string,
      currentStatus: string,
    ) => {
      try {
        const previousStatus = currentStatus

        await updateStatusCheckin({ id, status: newStatus })
        handleStatusChange(newStatus)
        const translatedStatus = t(`StockCheckin.status.${newStatus}`)
        showToastSuccess(
          t("StockCheckin.attributes.statusSuccess", {
            code: id,
            status: translatedStatus,
          }),
          {
            action: (
              <button
                onClick={async () => {
                  await revertStatusCheckin({ id })
                  handleStatusChange(previousStatus)
                  const translatedStatusPrevious = t(
                    `StockCheckin.status.${previousStatus}`,
                  )
                  showToastInfo(
                    t("StockCheckin.attributes.statusRevert", {
                      code: id,
                      status: translatedStatusPrevious,
                    }),
                    {},
                  )
                }}
                className="btn btn-link"
              >
                {t("Global.actions.undo")}
              </button>
            ),
          },
        )
      } catch (error) {
        const errorMessage =
          (error as { response?: { data?: { messages?: string } } })?.response
            ?.data?.messages || t("Global.alerts.internalServerError")
        showToastDanger(errorMessage as string, {})
      }
    }

    return (
      <>
        <tr>
          <td className={`${subcheckin && "bg-light"}`}>
            {!subcheckin ? (
              checkin.subCheckins?.length > 0 ? (
                <Button
                  disabled={subcheckin}
                  variant="link"
                  onClick={() => setIsOpen(!isOpen)}
                  data-testid={`collapse-button-${checkin.totalValue}`}
                >
                  {isOpen ? <BsChevronDown /> : <BsChevronRight />}
                </Button>
              ) : (
                <div className="bg-white"></div>
              )
            ) : (
              <div className="bg-white"></div>
            )}
          </td>
          <td className={`${subcheckin && "bg-light"}`}>
            {intlFormatDateTime(checkin.createdAt)}
          </td>
          <td className={`${subcheckin && "bg-light"}`}>
            <div className="d-flex align-items-center gap-2">
              <span className="m-0 p-0">{checkin.id} </span>
              {checkin.additionalInformation && (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{checkin.additionalInformation}</Tooltip>}
                >
                  <div>
                    <BsInfoCircleFill className="mb-1" color="gray" />
                  </div>
                </OverlayTrigger>
              )}
            </div>
          </td>
          <StyledTd className={`limite-caracteres ${subcheckin && "bg-light"}`}>
            {checkin.createdByName ? dashOrName(checkin.createdByName) : "-"}
          </StyledTd>
          {checkin.supplier === null ? (
            <td
              className={`limite-caracteres text-danger fst-ita ${subcheckin && "bg-light"}`}
              title={checkin.supplierName}
            >
              {checkin.supplierName === "" ? "-" : checkin.supplierName}
            </td>
          ) : (
            <td
              className={`limite-caracteres ${subcheckin && "bg-light"}`}
              title={checkin.supplier?.name}
            >
              {checkin.supplier?.name === "" ? "-" : checkin.supplier?.name}
            </td>
          )}
          <td className={`limite-caracteres ${subcheckin && "bg-light"}`}>
            {checkin.supplier === null ? (
              <span className="text-danger fst-italic">Ag. identificação </span>
            ) : checkin.supplier?.supplierResponsible === null ? (
              "-"
            ) : (
              checkin.supplier?.supplierResponsible?.name
            )}
          </td>
          <td className={`${subcheckin && "bg-light"}`}>
            {checkin.gooxxySolutions.length
              ? formatSolutionsText(checkin.gooxxySolutions)
              : "-"}
          </td>
          <td className={`${subcheckin && "bg-light"} text-end`}>
            {checkin.totalValue === "0.0" || checkin.totalValue === null ? (
              "-"
            ) : (
              <FormatMoney amount={Number(checkin?.totalValue)} />
            )}
          </td>
          <td className={`${subcheckin && "bg-light"} `}>
            <ChangeStatusPopover
              inputOptionName={(option) => t(`StockCheckin.status.${option}`)}
              inputOptions={[
                "awaiting_identification",
                "awaiting_analysis",
                "available",
                "non_adherent",
              ].filter((option) => option !== currentStatus)}
              badgeName={t(`StockCheckin.status.${currentStatus}`)}
              currentStatus={currentStatus}
              handleEdit={(newStatus) =>
                handleEditStatusCheckin(checkin.id, newStatus, currentStatus)
              }
              badgeMap={badgeMap}
              title={t("StockCheckin.changeStatus")}
              data-testid={`change-status-popover-${checkin.id}`}
              showOnlyDropdown={true}
              setSuccess={() => false}
            />
          </td>
          <td className={`${subcheckin && "bg-light"} text-end`}>
            <CheckinTableActionButtons
              item={checkin}
              handleReloadTable={handleReloadTable}
              handleFeedback={handleFeedback}
              handleDeleteCheckin={handleDeleteCheckin}
              handleSolutions={handleSolutions}
              loading={isLoading}
              success={success}
              originCheckin={subcheckin ? originalCheckin : undefined}
              checkins={checkins}
            />
          </td>
        </tr>
        <tr className="m-0 p-0">
          <td className="m-0 bg-cream t p-0" colSpan={10}>
            <Collapse className="m-0 p-0" in={isOpen}>
              <div>
                <Table className="table-hover m-0 p-0">
                  {checkin.subCheckins?.length > 0 ? (
                    <CheckinTableHead isSubcheckin />
                  ) : null}
                  <tbody className="align-middle m-0">
                    {checkin.subCheckins?.map(
                      (subcheckin: StockCheckin, index: number) => (
                        <Fragment key={index}>
                          {generateColumns(subcheckin, true, checkin)}
                        </Fragment>
                      ),
                    )}
                  </tbody>
                </Table>
              </div>
            </Collapse>
          </td>
        </tr>
      </>
    )
  }

  return (
    <>
      {checkins.map((checkin: StockCheckin) => (
        <>{generateColumns(checkin, false)}</>
      ))}
    </>
  )
}

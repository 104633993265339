/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEvent,
  ClipboardEvent,
  Dispatch,
  KeyboardEvent,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
} from "react"
import { Button, Card, Form, InputGroup, Spinner } from "react-bootstrap"
import { CustomAlert } from "../Customs/CustomAlert"
import { useParams } from "react-router-dom"
import { t } from "i18next"
import {
  CountryCode,
  useCountryConfigs,
} from "../../utils/hooks/useCountryConfigs"

type Props = {
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void
  children: ReactNode
  messageFeedback: string
  isDisabled?: boolean
  loading?: boolean
  setInputValues: Dispatch<SetStateAction<string[]>>
  inputValues: string[]
  isActive?: boolean
  isError?: boolean
  setIsError: Dispatch<SetStateAction<boolean>>
  buttonText: string
}

export default function PrivateComponent({
  handleSubmit,
  children,
  messageFeedback,
  inputValues,
  isActive,
  setInputValues,
  isDisabled,
  isError,
  loading,
  setIsError,
  buttonText,
}: Readonly<Props>) {
  const { country } = useParams()
  const {
    countryCode: [, setCountryCode],
  } = useCountryConfigs()

  const inputRefs: React.MutableRefObject<HTMLInputElement | any>[] =
    Array.from({ length: 4 }, () => useRef<HTMLInputElement | any>())

  useEffect(() => {
    const countryCode: CountryCode = (country as CountryCode) ?? "BR"
    countryCode && setCountryCode(countryCode)
  }, [country])

  const handleInputChange = (
    index: number,
    e: ChangeEvent<HTMLInputElement>,
  ): void => {
    const value = e.target.value

    if (/^[0-9a-zA-Z]$/.test(value) || value === "") {
      const newInputValues = [...inputValues]
      newInputValues[index] = value
      setInputValues(newInputValues)

      if (value.length === 1 && index < 3) {
        inputRefs[index + 1]?.current?.focus()
      }
    }
  }

  const handleBackspace = (
    index: number,
    e: KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (index > 0 && inputRefs[index]?.current && e.key === "Backspace") {
      const newInputValues = [...inputValues]
      newInputValues[index] = ""
      setInputValues(newInputValues)
      inputRefs[index - 1]?.current?.focus()
    } else if (index === 0 && e.key === "Backspace") {
      const newInputValues = [...inputValues]
      newInputValues[index] = ""
      setInputValues(newInputValues)
    }
  }

  const handlePasteGeneric = (
    e: ClipboardEvent<HTMLInputElement>,
    startIndex = 0,
  ): void => {
    e.preventDefault()
    const clipboardData = e.clipboardData.getData("text")
    const characters = clipboardData.replace(/\s/g, "").split("").slice(0, 4)
    const newInputValues = [...inputValues]

    for (let i = 0; i < characters.length; i++) {
      const index = startIndex + i
      if (inputRefs[index]?.current && /^[0-9a-zA-Z]$/.test(characters[i])) {
        newInputValues[index] = characters[i]
        inputRefs[index]!.current!.value = characters[i]
      }
    }

    setInputValues(newInputValues)
  }

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) =>
    handlePasteGeneric(e)
  const handlePasteFirstInput = (e: ClipboardEvent<HTMLInputElement>) =>
    handlePasteGeneric(e, 0)

  if (!isActive) {
    return (
      <Form onSubmit={handleSubmit}>
        <div
          className="d-flex justify-content-center align-items-center bg-secondary bg-opacity-50"
          style={{
            height: "100vh",
            width: "100vw",
            backgroundColor: "rgb(83, 95, 88)",
          }}
        >
          <Card style={{ width: "25rem", padding: "1rem", zIndex: "50" }}>
            <Card.Img
              className="px-2"
              variant="top"
              src="/images/LOGO_ESCURA.svg"
              style={{ width: "180px" }}
            />
            <Card.Body className="p-2 m-2">
              <CustomAlert
                show={isError}
                onClose={() => setIsError(false)}
                variant="warning"
                noIcon
              >
                <span
                  dangerouslySetInnerHTML={{ __html: messageFeedback ?? "" }}
                />
              </CustomAlert>
              <Card.Text>
                {t("Repurposal.attributes.enterAccessCode")}
              </Card.Text>
              <div className="d-flex flex-column gap-3">
                <div className="d-flex gap-2">
                  {inputRefs.map((ref, index) => (
                    <div className="d-flex gap-2 w-100" key={index}>
                      <InputGroup className=" w-25 w-100">
                        <Form.Control
                          data-testid={`input-${index}`}
                          className="py-3 text-center fs-3 text-uppercase "
                          ref={ref}
                          value={inputValues[index]}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleInputChange(index, e)
                          }
                          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) =>
                            handleBackspace(index, e)
                          }
                          onPaste={(e: ClipboardEvent<HTMLInputElement>) =>
                            index === 0
                              ? handlePasteFirstInput(e)
                              : handlePaste(e)
                          }
                        />
                      </InputGroup>
                    </div>
                  ))}
                </div>
                {isDisabled && (
                  <span className="text-danger">
                    {t("Global.alerts.requiredField")}
                  </span>
                )}
              </div>
              <Button
                type="submit"
                variant="success"
                disabled={
                  inputValues.some((value) => value.length !== 1) || loading
                }
                className="w-100 mt-4"
                data-testid="accessButton"
              >
                {loading ? <Spinner size="sm" /> : buttonText}
              </Button>
            </Card.Body>
          </Card>
        </div>
      </Form>
    )
  }
  return <>{children}</>
}

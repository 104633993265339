import { Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Label } from "../../../../../../../components/Form/formComponents/Label"
import { DropdownInput } from "../../../../../../../components/DropdownInput"
import { useInfiniteQuery } from "@tanstack/react-query"
import { useCountryConfigs } from "../../../../../../../utils/hooks/useCountryConfigs"
import { useDebounceValue } from "usehooks-ts"
import { billsList } from "../../../../../../../services/bills.service"
import { CodeAndCustomersOptions } from "../../../../../../../components/Form/formComponents/CodeAndCustomersOptions/CodeAndCustomersOptions"
import { determinePrimarySupplier } from "../../../../../Bills/utils/determinePrimarySupplier/determinePrimarySupplier"
import { capitalizeText } from "../../../../../../../utils/Format"
import { FormatMoney } from "../../../../../../../components/Formats/FormatMoney"
import { Info } from "../../../../../../../components/Info"

interface SimpleIndexBillQuery {
  count: number
  pageNumber: number
  bills: Array<SimpleIndexBill>
}

function getNextPageParam({ pageNumber, count }: SimpleIndexBillQuery) {
  const ITEMS_PER_PAGE = 20
  return pageNumber < Math.ceil(count / ITEMS_PER_PAGE)
    ? pageNumber + 1
    : undefined
}

export default function InvoiceBillInput(props: DefaultInvoiceFormsProps) {
  const { t } = useTranslation()
  const {
    countryCode: [countryCode],
  } = useCountryConfigs()
  const { invoice, setInvoice } = props
  const [search, setSearch] = useDebounceValue("", 500)

  async function fetchBills({ pageParam }: { pageParam: number }) {
    if (!invoice?.repurposalCode) throw new Error("Repurposal code is required")
    const { data } = await billsList({
      pageNumber: pageParam,
      context: "simple_index",
      filters: {
        repurposalCode: invoice?.repurposalCode,
        billNumber: search,
      },
    })
    return { bills: data.bills, count: data.count, pageNumber: pageParam }
  }

  const { data, isFetching, fetchNextPage } = useInfiniteQuery({
    queryKey: [
      "bills",
      "simple_index",
      countryCode,
      search,
      invoice?.repurposalCode,
    ],
    queryFn: fetchBills,
    getNextPageParam,
    initialPageParam: 1,
    enabled: !!invoice?.repurposalCode,
  })

  return (
    <Form.Group className="mb-3" controlId="invoice-bill-id">
      <Label className="d-flex gap-1 align-items-center">
        {t("Bills.attributes.billNumber")}
        <Info>{t("Invoice.alerts.warning.billsSuggestion")}</Info>
      </Label>
      <DropdownInput<Partial<SimpleIndexBill>>
        value={invoice?.billId ? { id: invoice.billId } : undefined}
        onClick={(bill) => setInvoice({ ...invoice, billId: bill.id })}
        onChange={(event) => setSearch(event.target.value)}
        options={
          (data?.pages && data.pages.length > 1
            ? data?.pages.flatMap((page) => page.bills)
            : data?.pages[0].bills) ?? []
        }
        renderName={(bill) => String(bill.id)}
        renderOption={(bill) => (
          <CodeAndCustomersOptions
            code={bill.id}
            firstCustomer={determinePrimarySupplier(bill)}
            firstCustomerLabel={capitalizeText(
              t("Bills.attributes.sender.one"),
            )}
            firstCustomerSize={4}
            secondCustomer={
              bill.repurposalCore
                ? {
                    ...bill.repurposalCore?.buyer,
                    document: bill.repurposalCore?.buyerDocument,
                  }
                : undefined
            }
            secondCustomerLabel={capitalizeText(
              t("Bills.attributes.receiver.one"),
            )}
            secondCustomerSize={4}
            additionalContent={
              <div className="col-4 text-truncate text-end">
                <strong>{t("Global.attributes.value") + ": "}</strong>
                <FormatMoney
                  amount={bill.amount ? Number(bill.amount) : undefined}
                />
              </div>
            }
          />
        )}
        isLoading={isFetching}
        onScroll={() => fetchNextPage()}
        disabled={!invoice?.repurposalCode}
        select
      />
    </Form.Group>
  )
}

import { t } from "i18next"
import { useInfiniteQuery } from "@tanstack/react-query"
import { getTagsList } from "../../../../services/catalog.service"
import { Dropdown } from "react-bootstrap"
import TagsView from "./components/TagsView"
import DropdownInputMultiSelect from "../../../DropdownInputMultiSelect"
import { isEqual } from "lodash"
import { useState } from "react"

function getNextPageParam<T extends CatalogTag | Tag>(
  lastPage: SimpleIndexTagsQuery<T>,
) {
  return lastPage.page + 1
}

export default function DropdownTagsInputs<
  T extends CatalogTag | Tag = CatalogTag,
>({
  tags,
  setTags,
  tagsRequest = getTagsList,
  createNewTag,
  tagValue,
}: DropdownTagsInputProps<T>) {
  const [search, setSearch] = useState("")

  function handleItemClick(selectedTag: T) {
    setTags((prevTags) => {
      const isTagSelected = prevTags.some((tag) => isEqual(tag, selectedTag))
      if (isTagSelected) {
        return prevTags.filter((tag) => !isEqual(tag, selectedTag))
      } else {
        return [...prevTags, selectedTag]
      }
    })
  }

  async function fetchTags({
    pageParam,
  }: {
    pageParam: number
  }): Promise<SimpleIndexTagsQuery<T>> {
    const response = await tagsRequest({
      search,
      pageNumber: pageParam,
    })
    return { tags: response.tags, page: pageParam, count: response.count }
  }

  const query = useInfiniteQuery({
    queryKey: ["tags", search],
    queryFn: fetchTags,
    initialPageParam: 1,
    getNextPageParam,
    staleTime: 0,
  })

  function handleSearch(event: React.ChangeEvent<HTMLInputElement>) {
    setSearch(event.target.value)
  }

  function handleScroll() {
    query.fetchNextPage()
  }

  async function handleNewTag() {
    if (
      search !== "" &&
      query.data?.pages.flatMap((page: SimpleIndexTagsQuery<T>) => page.tags)
        .length === 0
    ) {
      const newTag = await createNewTag(search)
      setTags((prevState) => [...prevState, newTag])
      setSearch("")
    }
  }

  return (
    <>
      <TagsView tags={tags} setTags={setTags} tagValue={tagValue} />
      <DropdownInputMultiSelect<T>
        onClick={handleItemClick}
        array={query.data?.pages.flatMap(
          (page: SimpleIndexTagsQuery<T>) => page.tags,
        )}
        description={t("Global.actions.select") ?? ""}
        renderName={tagValue}
        renderValue={tagValue}
        value={tags}
        onChange={handleSearch}
        onScroll={handleScroll}
        isLoading={query.isLoading}
        selecteds={tags.map((tag) => tagValue(tag)) ?? []}
        count={query.data?.pages.slice(-1)[0].count}
        emptyState={
          <Dropdown.Header className="text-center">
            {t("Catalog.actions.pressEnterCreateTag")}
          </Dropdown.Header>
        }
        onEnterPress={handleNewTag}
        search={search}
      />
    </>
  )
}

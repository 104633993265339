import { useEffect, useState } from "react"
import { BsChevronLeft, BsChevronRight } from "react-icons/bs"
import { useNavigate } from "react-router-dom"
import { PaginationTableProps } from "./pagination"
import { ButtonPagination } from "./styles"
import { Trans, useTranslation } from "react-i18next"
import { useCountryConfigs } from "../../utils/hooks/useCountryConfigs"

export function PaginationTable(props: PaginationTableProps) {
  const {
    firstPageIndex,
    itemsPerPage = 20,
    lastPageIndex,
    routerLink = null,
    totalItems,
    setPage,
    page,
    isText = true,
    buttonColor = "transparent",
  } = props

  const { findPluralRule } = useCountryConfigs()
  const { t } = useTranslation()
  const [currentPage, setCurrentPage] = useState(Number(page) || 1)
  const totalPages = Math.ceil(Number(totalItems) / itemsPerPage)
  const navigate = useNavigate()

  useEffect(() => {
    if (Number(page) !== currentPage) {
      setCurrentPage(Number(page))
    }
  }, [page, currentPage])

  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1,
  )

  const handlePageChange = (pageNumber: number) => {
    if (typeof pageNumber === "number") {
      setCurrentPage(pageNumber)
      setPage(pageNumber)
      routerLink && navigate(`${routerLink}${pageNumber}`)
    }
  }

  const renderPageNumbers = () => {
    const visiblePages: (number | "...")[] = []

    if (totalPages <= 5) {
      visiblePages.push(...pageNumbers)
    } else if (currentPage <= 2) {
      visiblePages.push(...pageNumbers.slice(0, 4), "...")
    } else if (currentPage >= totalPages - 1) {
      visiblePages.push(1, "...", ...pageNumbers.slice(totalPages - 3))
    } else {
      visiblePages.push(
        1,
        "...",
        currentPage - 1,
        currentPage,
        currentPage + 1,
        "...",
        totalPages,
      )
    }

    return (
      <>
        {visiblePages.map((pageNumber, index) => {
          const isCurrentPage = pageNumber === currentPage
          const variant = isCurrentPage ? "primary" : "outline-secondary"
          const disabled = pageNumber === "..."

          return (
            <ButtonPagination
              key={index}
              size="sm"
              pageNumber={
                typeof pageNumber === "number" ? String(pageNumber) : ""
              }
              buttonColor={buttonColor}
              isCurrentPage={isCurrentPage}
              variant={variant}
              className="border"
              onClick={() =>
                handlePageChange(
                  typeof pageNumber === "number" ? pageNumber : currentPage,
                )
              }
              disabled={disabled}
              data-testid={`pagination-button-${pageNumber}`}
            >
              {pageNumber}
            </ButtonPagination>
          )
        })}
      </>
    )
  }

  return (
    <div className="d-flex justify-content-between py-2">
      <p
        className="text-md text-gray-700"
        style={{
          display: isText ? "block" : "none",
        }}
      >
        <Trans
          i18nKey="Global.actions.showRecords"
          values={{
            first: firstPageIndex,
            last: lastPageIndex,
            total: totalItems,
            records: t(
              `Global.attributes.record.${findPluralRule(totalItems)}`,
            ).toLowerCase(),
          }}
        >
          ...<span className="fw-bold">...</span>...
          <span className="fw-bold">...</span>...
          <span className="fw-bold justify-content-between">...</span>...
        </Trans>
      </p>
      <div>
        <nav className="d-inline-flex gap-1" aria-label="Pagination">
          <ButtonPagination
            buttonColor={buttonColor}
            onClick={() => handlePageChange(currentPage - 1)}
            size="sm"
            variant="outline-secondary"
            className="border"
            disabled={currentPage === 1}
            data-testid="pagination-previous-button"
          >
            <BsChevronLeft />
          </ButtonPagination>

          {renderPageNumbers()}

          <ButtonPagination
            buttonColor={buttonColor}
            size="sm"
            onClick={() => handlePageChange(currentPage + 1)}
            variant="outline-secondary"
            disabled={currentPage === totalPages}
            className="border"
            data-testid="pagination-next-button"
          >
            <BsChevronRight />
          </ButtonPagination>
        </nav>
      </div>
    </div>
  )
}

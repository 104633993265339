import { getCountryCode } from "../../../../../utils/hooks/useCountryConfigs/utils"
import { capitalizeText } from "../../../../../utils/Format"

export const PAYMENT_DATA_LABEL_MAP: { [key: string]: { label: string } } = {
  "Boleto bancário": {
    label: "BillParts.attributes.billetBarcode",
  },
  PIX: {
    label: "BillParts.attributes.QRCode",
  },
}

export const BANK_FIELDS_MAP = [
  "Global.attributes.bank",
  "Global.attributes.agency",
  "Global.attributes.checkingAccount",
  [
    `Global.attributes.document.${getCountryCode()}`,
    "Global.attributes.document.document",
  ],
  "Receipts.attributes.favored",
]

export const PAYMENT_TYPES_WITHOUT_PAYMENT_TYPE_DATA = ["Tino"]

export function findMapedPaymentType(paymentType?: string) {
  return !!paymentType && paymentType in PAYMENT_DATA_LABEL_MAP
}

export function findPaymentTypeLabel(paymentType?: string) {
  return capitalizeText(
    paymentType && paymentType in PAYMENT_DATA_LABEL_MAP
      ? PAYMENT_DATA_LABEL_MAP[paymentType].label
      : "BillParts.attributes.bankData",
  )
}

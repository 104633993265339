import { Table } from "react-bootstrap"
import React, { Fragment, useId } from "react"
import { TableSkeleton } from "../../Tables/TableSkeleton"
import { TableEmptyState } from "../../TableEmptyState"
import { PaginationTable } from "../../Pagination"
import { CustomTableAccordionRow } from "./components/CustomTableAccordionRow"
import { CustomTableRow } from "./components/CustomTableRow"
import { CustomTableBody } from "./components/CustomTableBody"

export function CustomTable<T>(props: CustomTableProps<T>) {
  const {
    items,
    columns,
    isLoading = false,
    itemsPerPage,
    page,
    setPage,
    totalItems,
    variant = "white",
    totalizers = <></>,
    otherContent = <></>,
    responsive = "lg",
    fixedTableHead,
    accordion,
    accordionBody,
    rowShouldHaveArrow = () => true,
    itemKey,
    isPaginate = true,
    className,
    headersClassName,
    rowsClassName,
    tbodyWhite = true,
    borderInherit,
    overflowVisible = false,
  } = props

  const tableId = useId()
  const rowId = itemKey
    ? (item: T, _id: number) => itemKey(item)
    : (_item: T, id: number) => tableId + String(id)
  let columnsLenght = columns.length
  if (accordion) columnsLenght++

  return (
    <>
      <div
        style={{
          overflow: overflowVisible ? "visible" : "auto",
          maxWidth: "100vw",
        }}
      >
        <Table
          variant={variant}
          responsive={responsive}
          className={`table-hover  mb-4${tbodyWhite ? " tbody-white" : ""}${fixedTableHead ? " fix-table-head" : ""}${borderInherit ? " table-border-inherit" : ""} ${className ?? ""}`}
          hover
          data-testid={props["table-data-testid"]}
        >
          {columns.filter((item) => item.name !== undefined) && (
            <thead>
              <tr>
                {accordion && <th />}
                {columns.map((item: Column<T>) => (
                  <Fragment key={`thead-tr-th-${item.name}`}>
                    {!item.isNoVisible && (
                      <th
                        className={`col-${item.size ?? "auto"} text-nowrap text-${
                          item.direction ?? "start"
                        } ${item.thClassName ?? ""} ${headersClassName ?? ""}`}
                        data-testid={
                          item["th-data-testid"] && item["th-data-testid"]
                        }
                      >
                        {item.name}
                      </th>
                    )}
                  </Fragment>
                ))}
              </tr>
            </thead>
          )}

          <CustomTableBody accordion={accordion}>
            {isLoading ? (
              <TableSkeleton cols={columnsLenght} className={rowsClassName} />
            ) : items?.length > 0 ? (
              <>
                {items.map((item: T, index: number) => (
                  <Fragment key={rowId(item, index)}>
                    {accordion ? (
                      <CustomTableAccordionRow
                        accordionBody={accordionBody}
                        item={item}
                        index={index}
                        columns={columns}
                        rowId={rowId(item, index)}
                        rowClassName={rowsClassName}
                        rowShouldHaveArrow={rowShouldHaveArrow}
                      />
                    ) : (
                      <tr>
                        <CustomTableRow
                          item={item}
                          columns={columns}
                          rowId={rowId(item, index)}
                          index={index}
                          rowClassName={rowsClassName}
                        />
                      </tr>
                    )}
                  </Fragment>
                ))}
                {otherContent}
                <tr className={`totalizer-table`}>{totalizers}</tr>
              </>
            ) : (
              <TableEmptyState
                totalColumns={columnsLenght}
                className={rowsClassName}
              />
            )}
          </CustomTableBody>
        </Table>
      </div>
      {totalItems && totalItems > 0 && itemsPerPage && page && isPaginate ? (
        <PaginationTable
          itemsPerPage={itemsPerPage}
          page={page}
          totalItems={totalItems}
          setPage={setPage}
          firstPageIndex={(page - 1) * itemsPerPage + 1}
          lastPageIndex={(page - 1) * itemsPerPage + items.length}
        />
      ) : (
        <> </>
      )}
    </>
  )
}

import {
  CreateCatalogItemsResponse,
  GtinRelationsPayloadProps,
  RelationshipItemsProps,
  UpdateItemProps,
} from "../pages/CatalogItem/catalogItem"
import { api } from "./api"

export async function createCatalogItems(payload: CreateCatalogItemsResponse) {
  const { data } = await api.post(`/catalogs/items`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
  return data
}

export async function updateCatalogItem(id: string, payload: UpdateItemProps) {
  const { data } = await api.patch(`/catalogs/items/${id}`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
  return data
}

interface manufacturerListProps {
  pageNumber?: number
  search?: string
  itemsPerPage?: number
}

export async function manufacturerList({
  pageNumber = 1,
  search = "",
  itemsPerPage = 20,
}: manufacturerListProps) {
  const { data } = await api.get(
    `/catalogs/manufacturers?pageNumber=${pageNumber}&search=${search}&itemsPerPage=${itemsPerPage}`,
  )
  return data
}

export async function createManufacturer(name: string) {
  const { data } = await api.post(`/catalogs/manufacturers`, {
    name: name,
    corporateName: name,
  })
  return data
}

export async function updateManufacturer(id: number, name: string) {
  const { data } = await api.put(`/catalogs/manufacturers/${id}`, {
    name: name,
    corporateName: name,
  })
  return data
}

export async function removeManufacturer(id: number) {
  const { data } = await api.delete(`/catalogs/manufacturers/${id}`)
  return data
}

export async function catalogList(props: getSearchGeneralProps) {
  const { itemsPerPage = 30, pageNumber = 1, ...otherProps } = props

  const { data } = await api.get(`/catalogs/items`, {
    params: { itemsPerPage, pageNumber, ...otherProps },
  })
  return data
}

export async function getCatalogItemById(id: string) {
  const { data } = await api.get(`/catalogs/items/${id}`)
  return data
}

export async function deleteCatalogItem(id: string) {
  const { data } = await api.delete(`/catalogs/items/${id}`)
  return data
}

export async function putCatalogItemsRelationshipSku(
  id: string,
  payload: RelationshipItemsProps,
) {
  const { data } = await api.put(`/catalogs/items/${id}/item_skus`, payload)
  return data
}

export async function getGtinsRelations(id: string) {
  const { data } = await api.get(`/catalogs/items/${id}/gtins_relations`)
  return data
}

export async function getItemsEanDun({
  itemsPerPage,
  pageNumber,
  search,
  type,
}: getItemsEanDunProps) {
  const queryParams = new URLSearchParams({
    context: type,
    gtinType: type,
    search,
    itemsPerPage,
    pageNumber: pageNumber ? pageNumber : "",
  })
  const { data } = await api.get(`/catalogs/items?${queryParams.toString()}`)
  return data
}

export async function putGtinsRelations(
  payload: GtinRelationsPayloadProps,
  id: number | null,
) {
  const { data } = await api.put(`/catalogs/items/${id}/relationships`, payload)
  return data
}

export async function postBatchItems(file: File) {
  const formData = new FormData()
  formData.append("itemsFile", file)
  const { data } = await api.post("/catalogs/items/in_batches", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
  return data
}

export async function getTagsList({
  itemsPerPage = "20",
  pageNumber = 1,
  search,
}: getSearchGeneralProps) {
  const { data } = await api.get(`/catalogs/tags`, {
    params: { itemsPerPage, pageNumber, search },
  })
  return data
}

interface ExportCatalogProps {
  params: string
}

export async function catalogExport(props: ExportCatalogProps) {
  return await api.post(`/catalogs/items/export?${props.params}`)
}

export async function catalogBulkUpdate(file: File) {
  const formData = new FormData()
  formData.append("itemsFile", file)
  const { data } = await api.put("/catalogs/items/in_batches", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
  return data
}

export async function catalogItemsValidation(file: File) {
  const formData = new FormData()
  formData.append("itemsFile", file)
  const { data } = await api.post(
    "/catalogs/items/in_batches/validation",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  )
  return data
}

export async function getSkuGooxxy(sku: string) {
  const { data } = await api.get(`/catalogs/items/item_codes/gooxxy_sku/${sku}`)
  return data
}

export async function getGtinDuplicate(ean: string) {
  const { data } = await api.get(`/catalogs/items/codes/${ean}`)
  return data
}

export async function getItemsWithPalletLayer(hasPalletLayer: boolean) {
  const { data } = await api.get(`/catalogs/items`, {
    params: {
      hasPalletLayer: hasPalletLayer,
    },
  })
  return data
}

export async function getItemsWithBallastPallet(hasBallastPallet: boolean) {
  const { data } = await api.get(`/catalogs/items`, {
    params: {
      hasBallastPallet: hasBallastPallet,
    },
  })
  return data
}

export async function getItemsByRepurposalUnit(repurposalUnitIds: number[]) {
  const { data } = await api.get(`/catalogs/items`, {
    params: {
      repurposalUnitIds: repurposalUnitIds,
    },
  })
  return data
}

export async function getRepurposalUnits() {
  const { data } = await api.get(`/catalogs/repurposal_units`)
  return data
}

import React, { useEffect, useState } from "react"
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap"
import { t } from "i18next"
import DropdownInputMultiSelect from "../../../../../DropdownInputMultiSelect"
import { BsFillInfoCircleFill } from "react-icons/bs"
import { isArray } from "lodash"

export function FilterMultiSelect<T extends Record<string, string | number>>({
  labelName,
  options = [],
  filter,
  filterName,
  setFilter,
  renderName = (item: T) => ("name" in item ? (item["name"] as string) : ""),
  renderValue = (item: T) => ("id" in item ? (item["id"] as string) : ""),
  onChange,
  onScroll,
  isLoading,
  select,
  requiredField,
  count,
  disabled,
  info,
}: FilterMultiSelectProps<T>) {
  const [value, setValue] = useState<T[]>([])

  const handleItemClick = (selected: T): void => {
    const selectedValue = renderValue(selected)
    const currentFilter = filter[filterName] ?? []
    const newFilterItems =
      isArray(currentFilter) && currentFilter.includes(selectedValue)
        ? currentFilter.filter((item) => item !== selectedValue)
        : [...currentFilter, selectedValue]

    setValue(
      options.filter((option) => newFilterItems.includes(renderValue(option))),
    )
    setFilter((prevState) => ({
      ...prevState,
      [filterName]: newFilterItems,
    }))
  }

  useEffect(() => {
    if (!filter[filterName]) {
      setValue([])
    }
  }, [filter])

  return (
    <Form.Group className="mb-3 mt-3" controlId={filterName}>
      <Form.Label
        className="limite-caracteres-label mb-0"
        data-testid={`${filterName}Label`}
      >
        {labelName}
        {requiredField ? <span className="text-danger ms-2">*</span> : ""}
        {info && (
          <OverlayTrigger placement={"top"} overlay={<Tooltip>{info}</Tooltip>}>
            <span className="ms-2">
              <BsFillInfoCircleFill className="text-secondary text-start" />
            </span>
          </OverlayTrigger>
        )}
      </Form.Label>
      <DropdownInputMultiSelect
        onClick={handleItemClick}
        array={options}
        description={
          select
            ? (t("Global.actions.select") as string)
            : (t("Global.attributes.all") as string)
        }
        renderName={renderName}
        renderValue={renderValue}
        value={value}
        onChange={onChange}
        disabled={disabled}
        onScroll={onScroll}
        count={count}
        isLoading={isLoading}
        id={filterName}
        selecteds={(filter[filterName] as Array<string | number>) ?? []}
      />
    </Form.Group>
  )
}
